import get from "lodash/get";

export const getPaymentTerm = (term) => {
  const recurringCount = term || 12;
  return {
    ONE_TIME: {
      counter: 1,
      label: !(recurringCount % 12)
        ? `${(recurringCount / 12) > 1 ? (recurringCount / 12) : ''} Tahun`
        : `${recurringCount} Bulan`,
      type: 'one_time',
      text: 'Bayar Full'
    },
    SEMESTERLY: {
      counter: recurringCount / 6,
      label: '6 Bulan',
      type: 'semesterly',
      text: 'Per 6 Bulan'
    },
    QUARTERLY: {
      counter: recurringCount / 3,
      label: '3 Bulan',
      type: 'quarterly',
      text: 'Per 3 Bulan'
    },
    MONTHLY: {
      counter: recurringCount,
      label: 'Bulan',
      type: 'monthly',
      text: 'Perbulan'
    },
    YEARLY: {
      counter: recurringCount / 12,
      label: 'Tahun',
      type: 'yearly',
      text: 'Pertahun'
    },
  };
};

const SALESFORCE_PAYMENT_TERM_LABEL = {
  'ONETIME': {
    text: 'Bayar Full',
    label: '',
    recurringCount: 0
  },
  'Monthly': {
    text: 'Per Bulan',
    label: 'Bulan',
    recurringCount: 12
  },
  'Semesterly': {
    text: 'Per 6 Bulan',
    label: '6 Bulan',
    recurringCount: 2
  },
  'Quarterly': {
    text: 'Per 3 Bulan',
    label: '3 Bulan',
    recurringCount: 4
  }
};

/* 
* return the object related to opportunity payment term copy by paymentFrequency
*/
export const getSalesforcePaymentTerm = (paymentFrequency) => {
  return SALESFORCE_PAYMENT_TERM_LABEL[paymentFrequency];
}

export const autoDebitBanks = [
  {
    name: 'bankName',
    value: 'BRI',
    label: 'BRI',
  },
  {
    name: 'bankName',
    value: 'BNI',
    label: 'BNI',
  },
];

export const discountLib = [
  {
    label: '0%',
    value: '0'
  },
  {
    label: '5%',
    value: '5'
  },
  {
    label: '10%',
    value: '10'
  },
  {
    label: '15%',
    value: '15'
  },
  {
    label: '20%',
    value: '20'
  }
];

export const paymentMethodWithTerms = [
  'AUTODEBIT-AUTODEBIT',
  'RECURRING_MOTO-DOKU',
  'OTHER-DOKU',
  'OTHER-MIDTRANS',
  'OVO-XENDIT',
  'DANA-XENDIT',
  'LINKAJA-XENDIT',
];
export const PaymentMethodsWIthBanks = [
  'RECURRING_MOTO-DOKU',
  'OTHER-MIDTRANS',
  'OTHER-DOKU',
  'KREDIVO-DOKU',
  'OVO-XENDIT',
  'DANA-XENDIT',
  'LINKAJA-XENDIT',
];
export const getPaymentMethodLabel = (paymentMethod, paymentProvider) => {
  const paymentLabel = {
    'RECURRING_MOTO-DOKU': 'Kartu Kredit Bulanan',
    'OTHER-DOKU': 'DOKU Wallet / Transfer Bank / Kartu Kredit / Alfamart / Indomaret',
    'DANA-XENDIT': 'DANA',
    'LINKAJA-XENDIT': 'LINKAJA',
    'OVO-XENDIT': 'OVO',
    'OTHER-MIDTRANS': 'GOPAY / KlikBCA / BCA KlikPay / Akulaku',
    'INSTALLMENT-DOKU': 'Kartu Kredit Cicilan',
    'KREDIVO-DOKU': 'KREDIVO',
    'AUTODEBIT-AUTODEBIT': 'Debet Rekening (BRI & BNI)',
  };

  return get(paymentLabel, `${paymentMethod}-${paymentProvider}`, '-');
};

export const paymentTermLabel = {
  'MONTHLY': '12 Kali',
  'QUARTERLY': '4 Kali',
  'SEMESTERLY': '2 Kali',
  'ONE_TIME': '1 Kali',
}